import { Alert, AutoThemeProvider, Spinner, Table } from '@platform-ui/design-system';
import React, { useEffect, useState } from 'react';
import { PICKLIST_FIELDS } from '../constants';
import $ from 'jquery';
import Connect from '../../Connect/Connect';

const GenericTable = (props) => {
    // Table Consts
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [tableActions, setTableActions] = useState([]);
    const [rowActions, setRowActions] = useState([]);
    const [reloadTable, setReloadTable] = useState(false);
    const [noRows, setNoRows] = useState(false);
    const [columns, setColumns] = useState(() => {
        const savedColumns = JSON.parse(localStorage.getItem(`${props.table_name}_columns`));
        if (savedColumns) {
            const merged = savedColumns.map(savedColumn => {
                const matchedColumn = props.columns.find(col => col.field === savedColumn.field);
                if (matchedColumn?.dsRenderCell) {
                    savedColumn.dsRenderCell = matchedColumn.dsRenderCell;
                }
                return savedColumn;
            });

            return merged;
        } else {
            return props.columns;
        }
    });

    // Filter Consts
    const [clearFilter, setClearFilter] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [initialFilter, setInitialFilter] = useState({});
    const [filterableFields, setFilterableFields] = useState(props.filterableFields);

    // Alert Consts
    const [isAlert, setIsAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertHeader, setAlertHeader] = useState('');
    const [alertBody, setAlertBody] = useState('');

    // NOTE(Xander): In order to use older Connect modals with the new table, we need to replicate Rails-Ajax functionality
    // This method ensures that all links generated by the table will render their modal on the same page without redirecting
    const ajaxCall = () => {
        $('.MuiList-root a').attr('data-remote', true);
    }

    // NOTE(Duc): This is to fix the unaligned dropdown in filter
    $("<style>").text("div[data-e2e*='-filter-field-operator'] { padding-top: 13px; }").appendTo("head");

    // NOTE(Duc): This is to ensure filter popover will not disappear even when clear button is clicked
    // without any filter fields filled.
    $(document).on("click", `button[data-e2e='${props.tableArgs.e2e}-table-filter-clear']`, (event) => {
        setClearFilter(!clearFilter);
        setInitialFilter({});
        setFilteredRows(rows);
        setNoRows(false);
    });

    // NOTE(Michael): this is use to detech when the user is finished typing in the search bar
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchFromBackend(searchValue)
        }, 500)
        if (searchValue.length === 0 && rows.length > 0) {
            getObjects();
        }
        return () => clearTimeout(timer)
    }, [searchValue]);

    useEffect(() => {
        getObjects(null);
    }, [reloadTable]);

    const getObjects = async (event) => {
        setLoading(true);
        try {
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                })
            };
            const response = await window.fetch(props.backend_uri, options);
            if (!response.ok) throw Error(response.statusText);
            const data = await response.json();
            setRows(data.rows);
            setFilteredRows(data.rows);
            
            if (data && Array.isArray(data.rows) && data.rows.length > 0) {
                let isUpdated = false;
                data.rows.forEach((row) => {
                    const keys = Object.keys(row);
                    keys.forEach((key) => {
                        if (PICKLIST_FIELDS[props.tableArgs.e2e].includes(key)) {
                            const field = props.filterableFields.find((field) => field.name == key);
                            if (field) {
                                field.type = 'PICKLIST';
                                if (!field.picklistOptions) {
                                    field.picklistOptions = [];
                                } else {
                                    if (!field.picklistOptions.includes(row[key])) {
                                        field.picklistOptions.push(row[key]);
                                    }
                                }
                                isUpdated = true;
                            }
                        }
                    });
                });

                if (isUpdated) {
                    // sort picklist options
                    props.filterableFields.forEach((field) => {
                        if (field.type === 'PICKLIST') {
                            field.picklistOptions.sort();
                        }
                    });
                    setFilterableFields(props.filterableFields);
                }
            }

            setInitialFilter({});
            setClearFilter(!clearFilter);
            if (props.generateTableActions) {
                setTableActions(generateTableActions(props.isTableActions ? data.tableActions : data.table_actions));
            }
            if (props.setRowActions) {
                setRowActions(
                    [
                        {
                            icon: 'more_horiz',
                            onClick: getRowActions,
                            listChildren: []
                        }
                    ]
                );
            }
            if (data.rows.length === 0) {
                setNoRows(true);
            } else {
                setNoRows(false);
            }
            setLoading(false);
        } catch (error) {
            Connect.log(error);
            setRows([]);
            setFilteredRows([]);
            setInitialFilter({});
            setClearFilter(!clearFilter);
            setRowActions([]);
            setLoading(false);
            setNoRows(true);
        }
    }

    function generateTableActions(allowed_applications) {
        const tableActions = [
            {
                icon: "add_circle_outline",
                listChildren: []
            },
            {
                icon: "sync",
                label: props.refreshLabel,
                onClick: getObjects,
            }
        ];
        allowed_applications.forEach(element => {
            tableActions[0].listChildren.push(
                {
                    label: element.label,
                    href: element.path,
                    onClick: ajaxCall
                }
            );
        });
        return tableActions;
    }

    const getRowActions = async (event) => {
        try {
            let csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            // NOTE(Xander): When processing requests from billing, we must prepend /services/connect
            let path = '';

            if (props.rowActionsPath) {
                path = props.rowActionsPath.replace('[PLACE_HOLDER]', event.row.id);
            } else if (props.table_name === 'applications_datatable') {
                path = window.location.pathname + '/' + event.row.id + '/actions';
            } else {
                path = (window.location.pathname.includes('admin') ? '/admin' : '') + '/tools/tasks/actions/' + event.row.id;
            }

            if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
                path = '/services/connect' + path;
            }
            const response = await window.fetch(path, options);
            if (!response.ok) throw Error(response.statusText);
            const actions = await response.json();
            setRowActions(regenerateRowActions(actions));
        } catch (error) {
            Connect.log(error);
        }
    }

    function regenerateRowActions(actions) {
        return [
            {
                dsGetActionData: (row) => {
                    var listChildren = []
                    row.actions = actions
                    if (row.actions != null) {
                        var template_link = {}
                        row.actions.forEach((element) => {
                            template_link = {
                                label: element.label
                            };
                            if (element.ajaxCall) {
                                template_link.onClick = ajaxCall
                            }
                            if (element.method === 'get') {
                                template_link.href = element.path
                            } else {
                                template_link.onClick = () => {
                                    apiCall(element);
                                }
                            }
                            if (element.disabled) {
                                template_link.disabled = true
                            }
                            listChildren.push(template_link);
                        });
                        return {
                            icon: 'more_horiz',
                            listChildren: listChildren,
                            onClick: getRowActions
                        };
                    }
                }
            }
        ];
    }

    const apiCall = element => {
        try {
            // NOTE(Xander): This query grabs the rails CSRF token for requests
            let csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
            fetch(element.path, {
                method: element.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrf
                }
            }).then((response) => {
                setReloadTable(!reloadTable);
                if (response.status == 200 || response.ok == true) {
                    setAlertHeader('Successfully Performed Action');
                    // setAlertBody('Aren\'t you a cool guy!');
                    setAlertSeverity('success');
                } else {
                    setAlertHeader(`${response.status}: ${response.statusText}`);
                    setAlertBody('Please Try Again Later');
                    setAlertSeverity('error');
                }
                setIsAlert(true);
            }).catch((error) => {
                Connect.log(error);
            });
        } catch (error) {
            Connect.log(error);
        }
    }

    const fetchFromBackend = async (args, filteredData = null) => {
        if (args.length != 0) {
            setFetching(true);
            if (props.backend_uri.includes('?')) {
                var url = props.backend_uri + '&';
            } else {
                var url = props.backend_uri + '?';
            }

            try {
                const options = {
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    })
                };

                const params = filteredData != null ? new URLSearchParams({
                    ...args, iDisplayLength: props.iDisplayLength
                }) : new URLSearchParams({
                    sSearch: args, iDisplayLength: props.iDisplayLength
                });

                const response = await window.fetch(url + params, options);
                if (!response.ok) throw Error(response.statusText);
                const data = await response.json();
                
                var ids = new Set(filteredData != null ? filteredData.map(d => props.table_name !== 'histories_datatable' ? d.id : d.versions__id)
                                                       : []);
                var merged = filteredData != null ? [...filteredData, ...data.rows.filter(d => props.table_name !== 'histories_datatable' ? !ids.has(d.id) : !ids.has(d.versions__id))]
                                                  : [...data.rows];

                if (data.rows.length === 0 && (filteredData === null || filteredData.length === 0)) {
                    setNoRows(true);
                } else {
                    setNoRows(false);
                }

                setFilteredRows(merged);

                if (filteredData != null) {
                    ids = new Set(rows.map(d => d.id));
                    setRows([...rows, ...merged.filter(d => !ids.has(d.id))]);
                } else {
                    setRows(merged);
                }

                setFetching(false);
            } catch (error) {
                Connect.log(error);
                setFetching(false);
            }
        }
    }

    const filter = (filterObj) => {
        if (filterObj && filterObj.conditions) {
            const conditions = filterObj.conditions;
            const data = rows.filter((row) => {
                return conditions.every((condition) => {
                    const { operator, field, value } = condition;
                    
                    const rowData = row[field]?.toString().toLowerCase();

                    if (operator === 'EQ') {
                        return rowData == value.toLowerCase();
                    } else if (operator === 'NE') {
                        return rowData != value.toLowerCase();
                    } else if (operator === 'SW') {
                        return rowData.startsWith(value.toLowerCase());
                    } else {
                        // LT, LE, GT, and GE operators will be handled by the backend
                        return false;
                    }
                });
            });
            const filters = { sFilter: true };
            conditions.forEach((condition) => {
                const key = condition.field;
                const operator = condition.operator;
                let type = props.filterableFields.find((field) => field.name === key).type;
                let value = condition.value;

                if (type === 'TIMESTAMP') {
                    if (isNaN(Date.parse(value))) { // invalid date input
                        value = (new Date()).toJSON().substring(0, 10);
                    }
                    type = value.length <= 10 ? 'date' : 'timestamp';
                } else {
                    type = 'string';
                }

                filters[key] = `${type},${operator},${value}`;
            });
            
            setFilteredRows(data);
            fetchFromBackend(filters, data);
        }
        setInitialFilter(filterObj);
    }

    return (
        <AutoThemeProvider>
            <Alert
                body={alertBody}
                dismissible
                dsOnClose={() => {
                    setIsAlert(false);
                }}
                e2e={props.e2eAlert}
                header={alertHeader}
                open={isAlert}
                severity={alertSeverity}
            />
            <Table
                {...props.tableArgs}
                loading={loading}
                columns={columns}
                rows={filteredRows}
                searchable
                rowsPerPage={20}
                rowActions={rowActions}
                tableActions={props.generateTableActions ? tableActions : [
                    {
                        icon: 'sync',
                        label: props.refreshLabel,
                        onClick: getObjects,
                    }
                ]}
                filterableFields={filterableFields}
                initialFilter={initialFilter}
                orderable={true}
                showNoRowsMessage={noRows}
                dsOnSearchChange={(args) => setSearchValue(args)}
                dsOnColumnsChange={(columns) => {
                    localStorage.setItem(`${props.table_name}_columns`, JSON.stringify(columns));
                }}
                dsOnFilter={(args) => filter(args.filter)}
            />
            <p style={fetching ? {} : { display: 'none' }}>
                <Spinner variant='linear' />
                <p>
                    Fetching from the database
                </p>
            </p>
        </AutoThemeProvider>
    );
};

export default GenericTable;