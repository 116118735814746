import { AutoThemeProvider, Card, DataSummary, Typography, Grid, Tabs, Tab } from '@platform-ui/design-system';
import GenericTable from '../Tables/components/GenericTable';
import { APPDOWNLOADS_TABLE_PROPS, TENANTS_TABLE_PROPS, USERS_TABLE_PROPS } from '../Tables/constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as ReactDOM from 'react-dom/client';

function OrganizationView(props) {
    const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
    const [tabs, setTabs] = useState(0);

    root.render(
        <AutoThemeProvider>
            <Grid item xs={12}>
              <div style={{ textAlign: "left", marginLeft: '40px' }}>
                <Typography e2e="myTypography" variant="overline">
                  Organization
                </Typography>
              </div>
              <div style={{ textAlign: "left" }}>
                <Typography e2e="myTypography" variant="h4">
                  {props.organization.name}
                </Typography>
              </div>
            </Grid>
            <Tabs
                a11yLabel="tab"
                value={tabs}
                dsOnChange={
                    (_, newIndex) => {
                        if (newIndex === 0) {
                            setTabs(0);
                        }
                        if (newIndex === 1) {
                            setTabs(1);
                        }
                        if (newIndex === 2) {
                          setTabs(2);
                      }
                    }
                }
                e2e="connectors-tabs"
                >
                    <Tab
                        id="overview-tab"
                        e2e="overview"
                        label="Overview" 
                        aria-controls={""}
                    />
                    <Tab
                        id="tenants-tab"
                        e2e="tenants"
                        label="Tenants"
                        aria-controls={""}
                    />
                    <Tab
                        id="entitlements-tab"
                        e2e="entitlements"
                        label="Apps / Entitlements"
                        aria-controls={""}
                    />
            </Tabs>
        </AutoThemeProvider>
      );
    return (
      <AutoThemeProvider>
        {tabs === 0  && <Grid container justify="space-evenly" spacing = "20">
            <Grid item xs={12}>
              <div style={{ textAlign: "left" }}>
                <Card
                body={<DataSummary data={props.organization} summary={[
                  {
                    "label": "Organization ID",
                    "labelTooltip": "A unique identifier",
                    "dataKey": "id",
                    "type": "string"
                  },
                  {
                    "label": "Name",
                    "dataKey": "name"
                  },
                  {
                    "label": "Type",
                    "labelTooltip": "Type of Organization",
                    "dataKey": "type",
                    "type": "string"
                  },
                  {
                    "label": "Approved",
                    "dataKey": "approved"
                  },
                  {
                    "label": "Salesforce ID",
                    "dataKey": "salesforce_id"
                  },
                  {
                    "label": "Slug",
                    "dataKey": "slug"
                  },
                  {
                    "label": "Tools Access",
                    "dataKey": "tools_access"
                  },
                  {
                    "label": "Tools Expire",
                    "dataKey": "tools_expire"
                  },
                  {
                    "label": "Trusted Emails",
                    "dataKey": "trusted_emails"
                  }
                ]}/>}
                  e2e="myCard"
                  id="org_card_header"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ textAlign: "left" }}>
                <Card
                  body={<GenericTable {...USERS_TABLE_PROPS} {...props.tables.users_table} />}
                  e2e="myCard"
                  header="Users"
                  id="org_card_tenant"
                />
              </div>
            </Grid>
          </Grid>}
          {tabs === 1  && <Card
                  body= {<GenericTable {...TENANTS_TABLE_PROPS} {...props.tables.tenants_table} />}
                  e2e="myCard"
                  header=""
                  id="tenant_card_tab"
                /> }
          {tabs === 2  && <Card
                  body= {<GenericTable {...APPDOWNLOADS_TABLE_PROPS} {...props.tables.appdownloads_table} />}
                  e2e="myCard"
                  header=""
                  id="entitlements_card_tab"
                /> }
        </AutoThemeProvider>
    );
}
OrganizationView.propTypes = {
  organization: PropTypes.object,
  tables: PropTypes.object
}

export default OrganizationView;
