import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export const APPDOWNLOADS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'appstore_downloads_datatable',
        uniqueKey: 'appstore_downloads_datatable'
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'workflow_state',
            label: 'Workflow State',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'updated_at',
            label: 'Updated on'
        },
        {
            field: 'created_at',
            label: 'Created on'
        },
        {
            field: 'downloadable_type',
            label: 'Downloadable type',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'rate_plan',
            label: 'Rateplan',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'downloadable_id',
            label: 'Downloadable Id',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'title',
            label: 'Title',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        }
    ],
    filterableFields: [
        {
            name: 'workflow_state',
            label: 'Workflow State',
            type: 'PICKLIST',
            picklistOptions: [
                "Aquired",
                "Trial",
                "Downloaded",
                "Awaiting Payment",
                "Deprovisioned",
                "New"
            ]
        },
        {
            name: 'updated_at',
            label: 'Updated on',
            type: 'TIMESTAMP'
        },
        {
            name: 'created_at',
            label: 'Created on',
            type: 'TIMESTAMP'
        },
        {
            name: 'downloadable_type',
            label: 'Downloadable type',
            type: 'STRING'
        }
    ],
    generateTableActions: true,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Application Downloads',
    rowActionsPath: '/admin/appstore/app_downloads/[PLACE_HOLDER]/actions',
    iDisplayLength: 100,
    e2eAlert: 'applicationAlert'
};

export const APPLICATIONBUILDS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'application_builds_datatable',
        uniqueKey: 'application_builds_datatable'
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'name',
            label: 'Name'
        },
        {
            field: 'application_name',
            label: 'Application Name'
        },
        {
            field: 'region',
            label: 'Region'
        },
        {
            field: 'version',
            label: 'Version'
        },
        {
            field: 'description',
            label: 'Description'
        },
        {
            field: 'application_type',
            label: 'Application Type'
        },
        {
            field: 'deleted',
            label: 'Deleted'
        },
        {
            field: 'file_size',
            label: 'Size'
        }
    ],
    filterableFields: [
        {
            name: 'name',
            label: 'Name',
            type: 'STRING'
        },
        {
            name: 'application_name',
            label: 'Application Name',
            type: 'STRING'
        },
        {
            name: 'region',
            label: 'Region',
            type: 'STRING'
        },
        {
            name: 'version',
            label: 'Version',
            type: 'STRING'
        },
        {
            name: 'description',
            label: 'Description',
            type: 'STRING'
        },
        {
            name: 'application_type',
            label: 'Application Type',
            type: 'STRING'
        },
        {
            name: 'deleted',
            label: 'Deleted',
            type: 'PICKLIST',
            picklistOptions: [
                "true",
                "false"
            ]
        }
    ],
    generateTableActions: true,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Application Builds',
    rowActionsPath: '/admin/tools/application_builds/[PLACE_HOLDER]/actions',
    iDisplayLength: 200,
    e2eAlert: 'applicationAlert'
};

export const APPLICATIONS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'applications_datatable',
        uniqueKey: 'applications_datatable'
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'name',
            label: 'Name',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'default_build',
            label: 'Default Build'
        },
        {
            field: 'queue_name',
            label: 'Queue Name'
        },
        {
            field: 'created_by',
            label: 'Owner',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'updated_by',
            label: 'Updated By',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'task_count',
            label: 'Task Count'
        }
    ],
    filterableFields: [
        {
            name: 'name',
            label: 'Name',
            type: 'STRING'
        }
    ],
    generateTableActions: true,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Applications',
    iDisplayLength: 100,
    e2eAlert: 'applicationAlert'
};

export const HISTORIES_TABLE_PROPS = {
    tableArgs: {
        e2e: 'histories_datatable',
        uniqueKey: 'histories_datatable'
    },
    columns: [
        {
            field: 'versions__id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'versions__event',
            label: 'Event'
        },
        {
            field: 'versions__created_at',
            label: 'Created at'
        },
        {
            field: 'versions__whodunnit',
            label: 'Updated By',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'object_changes',
            label: 'Changes'
        }
    ],
    filterableFields: [
        {
            name: 'versions__event',
            label: 'Event',
            type: 'STRING'
        },
        {
            name: 'versions__created_at',
            label: 'Created at',
            type: 'TIMESTAMP'
        },
        {
            name: 'versions__whodunnit',
            label: 'Updated By',
            type: 'STRING'
        }
    ],
    generateTableActions: false,
    isTableActions: false,
    setRowActions: false,
    refreshLabel: 'Refresh History',
    iDisplayLength: 100,
    e2eAlert: 'historyAlert'
};

export const IDENTITIES_TABLE_PROPS = {
    tableArgs: {
        e2e: 'identities_datatable',
        uniqueKey: 'identities_datatable'
    },
    columns: [
        {
            field: 'id',
            label: 'Id',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'provider',
            label: 'Provider',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'uid',
            label: 'uid',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'name',
            label: 'Name',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'email',
            label: 'Email',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'nickname',
            label: 'Nickname',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'hostname',
            label: 'Hostname',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'phone',
            label: 'Phone',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'info',
            label: 'Info',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'tenant_id',
            label: 'Tenant id',
            labelTooltip: 'A unique identifier',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'created_at',
            label: 'Created at'
        },
        {
            field: 'updated_at',
            label: 'Updated at'
        },
        {
            field: 'users_email',
            label: 'Users email'
        },
        {
            field: 'users_username',
            label: 'Users username',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        }
    ],
    filterableFields: [
        {
            name: 'name',
            label: 'Name',
            type: 'STRING'
        },
        {
            name: 'email',
            label: 'Email',
            type: 'STRING'
        },
        {
            name: 'hostname',
            label: 'Hostname',
            type: 'STRING'
        },
        {
            name: 'created_at',
            label: 'Created at',
            type: 'TIMESTAMP'
        },
        {
            name: 'updated_at',
            label: 'Updated at',
            type: 'TIMESTAMP'
        },
        {
            name: 'users_email',
            label: 'Users email',
            type: 'STRING'
        },
        {
            name: 'users_username',
            label: 'Users username',
            type: 'STRING'
        }
    ],
    generateTableActions: false,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Identities',
    rowActionsPath: '/admin/users/identities/[PLACE_HOLDER]/actions',
    iDisplayLength: 100,
    e2eAlert: 'identityAlert'
};

export const ORGANIZATIONS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'organizations_datatable',
        uniqueKey: 'organizations_datatable'
    },
    columns: [
        {
            field: 'name',
            label: 'Name'
        },
        {
            field: 'id',
            label: 'ID'
        },
        {
            field: 'type',
            label: 'Type'
        },
        {
            field: 'salesforce_id',
            label: 'Salesforce ID'
        },
        {
            field: 'account_id',
            label: 'Account ID'
        },
        {
            field: 'trusted_emails',
            label: 'Trusted Emails'
        },
        {
            field: 'tools_access',
            label: 'Tools Access'
        },
        {
            field: 'tools_expire',
            label: 'Tools Expire'
        },
        {
            field: 'user_count',
            label: 'Users'
        }
    ],
    filterableFields: [
        {
            name: 'name',
            label: 'Name',
            type: 'STRING'
        },
        {
            name: 'type',
            label: 'Type',
            type: 'STRING'
        },
        {
            name: 'salesforce_id',
            label: 'Salesforce ID',
            type: 'STRING'
        },
        {
            name: 'account_id',
            label: 'Account ID',
            type: 'STRING'
        },
        {
            name: 'tools_access',
            label: 'Tools Access',
            type: 'STRING'
        },
        {
            name: 'tools_expire',
            label: 'Tools Expire',
            type: 'TIMESTAMP'
        }
    ],
    generateTableActions: true,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Organizations',
    rowActionsPath: '/admin/organizations/[PLACE_HOLDER]/actions',
    iDisplayLength: 100,
    e2eAlert: 'organizationAlert'
};

export const TASKS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'tasksTable',
        uniqueKey: 'id',
        nestedDataKey: 'parent_id',
        showGroupedRowsControls: true,
        rowDisplayOptions: {
            highlight: false
        }
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'name',
            label: 'Name'
        },
        {
            field: 'created_by',
            label: 'Created By',
            dsRenderCell: ({ value }) => {
                return <a href={'/users/' + value + '/profile'}>{value}</a>;
            }
        },
        {
            field: 'start_time',
            label: 'Start Time'
        },
        {
            field: 'created_at',
            label: 'Created At'
        },
        {
            field: 'duration',
            label: 'Duration'
        },
        {
            field: 'results',
            label: 'Results'
        },
        {
            field: 'status',
            label: 'Status'
        },
        {
            field: 'mode',
            label: 'Mode'
        },
        {
            field: 'execution',
            label: 'Execution'
        },
        {
            field: 'application',
            label: 'Application'
        },
        {
            field: 'build',
            label: 'Build'
        }
    ],
    filterableFields: [
        {
            name: 'created_by',
            label: 'Created By',
            type: 'STRING'
        },
        {
            name: 'start_time',
            label: 'Start Time',
            type: 'TIMESTAMP'
        },
        {
            name: 'created_at',
            label: 'Created At',
            type: 'TIMESTAMP'
        },
        {
            name: 'duration',
            label: 'Duration',
            type: 'DECIMAL'
        },
        {
            name: 'execution',
            label: 'Execution',
            type: 'STRING'
        },
        {
            name: 'application',
            label: 'Application',
            type: 'STRING'
        }
    ],
    generateTableActions: true,
    isTableActions: true,
    setRowActions: true,
    refreshLabel: 'Refresh Tenants',
    iDisplayLength: 10,
    e2eAlert: 'taskAlert'
};

export const TENANTS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'tenants_datatable',
        uniqueKey: 'tenants_datatable'
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'name',
            label: 'Tenant',
            dsRenderCell: ({ row }) => {
                return <a href={row.tenant_link}>{row.name}</a>
            }
        },
        {
            field: 'tenant_id',
            label: 'Tenant ID'
        },
        {
            field: 'global_id',
            label: 'Global ID'
        },
        {
            field: 'node_id',
            label: 'Node ID'
        },
        {
            field: 'hostname',
            label: 'Hostname'
        },
        {
            field: 'slug',
            label: 'Slug'
        },
        {
            field: 'type',
            label: 'Type'
        },
        {
            field: 'environment',
            label: 'Environment'
        },
        {
            field: 'status',
            label: 'Status'
        },
        {
            field: 'organization',
            label: 'Organization',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        }
    ],
    filterableFields: [
        {
            name: 'name',
            label: 'Tenant',
            type: 'STRING'
        },
        {
            name: 'tenant_id',
            label: 'Tenant ID',
            type: 'STRING'
        },
        {
            name: 'global_id',
            label: 'Global ID',
            type: 'STRING'
        },
        {
            name: 'node_id',
            label: 'Node ID',
            type: 'STRING'
        },
        {
            name: 'hostname',
            label: 'Hostname',
            type: 'STRING'
        },
        {
            name: 'slug',
            label: 'Slug',
            type: 'STRING'
        },
        {
            name: 'type',
            label: 'Type',
            type: 'STRING'
        },
        {
            name: 'environment',
            label: 'Environment',
            type: 'STRING'
        },
        {
            name: 'status',
            label: 'Status',
            type: 'STRING'
        },
        {
            name: 'organization',
            label: 'Organization',
            type: 'STRING'
        }
    ],
    generateTableActions: false,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Tenants',
    rowActionsPath: '/admin/tenants/[PLACE_HOLDER]/actions',
    iDisplayLength: 100,
    e2eAlert: 'tenantAlert'
};

export const USERS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'users_datatable',
        uniqueKey: 'users_datatable',
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'username',
            label: 'Username',
            dsRenderCell: ({ value }) => {
                return <a href={'/users/' + value + '/profile'}>{value}</a>;
            }
        },
        {
            field: 'email',
            label: 'Email'
        },
        {
            field: 'company_position',
            label: 'Company/Position'
        },
        {
            field: 'slug',
            label: 'Organization',
            dsRenderCell: ({ value }) => {
                return <div> {ReactHtmlParser(value)} </div>
            }
        },
        {
            field: 'profile',
            label: 'Profile'
        },
        {
            field: 'current_sign_in_at',
            label: 'Last Sign In'
        }
    ],
    filterableFields: [
        {
            name: 'username',
            label: 'Username',
            type: 'STRING'
        },
        {
            name: 'email',
            label: 'Email',
            type: 'STRING'
        },
        {
            name: 'slug',
            label: 'Organization',
            type: 'STRING'
        },
        {
            name: 'profile',
            label: 'Profile',
            type: 'STRING'
        },
        {
            name: 'current_sign_in_at',
            label: 'Last Sign In',
            type: 'TIMESTAMP'
        }
    ],
    generateTableActions: false,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Users',
    rowActionsPath: '/admin/users/[PLACE_HOLDER]/actions',
    iDisplayLength: 100,
    e2eAlert: 'userAlert'
};

export const INTEGRATION_APPS_TABLE_PROPS = {
  tableArgs: {
      e2e: 'admin_integration_apps_datatable',
      uniqueKey: 'admin_integration_apps_datatable',
  },
  columns: [
      {
          field: 'id',
          label: 'ID',
          labelTooltip: 'A unique identifier'
      },
      {
          field: 'name',
          label: 'Name'
      },
      {
          field: 'status',
          label: 'Status'
      },
      {
          field: 'redirectUrl',
          label: 'Redirect Url'
      },
      {
          field: 'createdAt',
          label: 'Created At'
      },
      {
          field: 'updatedAt',
          label: 'Updated At'
      },
  ],
  filterableFields: [
      {
          field: 'name',
          label: 'Name',
          type: 'STRING'
      },
      {
          field: 'status',
          label: 'Status',
          type: 'STRING'
      },
  ],
  generateTableActions: true,
  isTableActions: true,
  setRowActions: true,
  refreshLabel: 'Refresh Integration Apps',
  rowActionsPath: '/admin/integration_apps/[PLACE_HOLDER]/actions',
  iDisplayLength: 100,
  e2eAlert: 'integrationAppsAlert'
};

export const INTEGRATION_APPS_SETTINGS_TABLE_PROPS = {
    tableArgs: {
        e2e: 'admin_integration_apps_settings_datatable',
        uniqueKey: 'admin_integration_apps_settings_datatable',
    },
    columns: [
        {
            field: 'id',
            label: 'ID',
            labelTooltip: 'A unique identifier'
        },
        {
            field: 'integrationAppsId',
            label: 'Integration App ID'
        },
        {
            field: 'tenantId',
            label: 'Tenant ID'
        },
        {
            field: 'status',
            label: 'Status'
        },
        {
            field: 'lastUpdatedBy',
            label: 'Last Updated By'
        },
        {
            field: 'createdAt',
            label: 'Created At'
        },
        {
            field: 'updatedAt',
            label: 'Updated At'
        },
    ],
    filterableFields: [
        {
            field: 'status',
            label: 'Status',
            type: 'STRING'
        },
    ],
    generateTableActions: false,
    isTableActions: false,
    setRowActions: true,
    refreshLabel: 'Refresh Integration Apps Setting',
    rowActionsPath: '/admin/integration_apps_settings/[PLACE_HOLDER]/actions',
    iDisplayLength: 100,
    e2eAlert: 'integrationAppsAlert'
  };

export const PICKLIST_FIELDS = {
    [APPDOWNLOADS_TABLE_PROPS.tableArgs.e2e]: [
        'downloadable_type'
    ],
    [APPLICATIONBUILDS_TABLE_PROPS.tableArgs.e2e]: [
        'region',
        'application_type'
    ],
    [APPLICATIONS_TABLE_PROPS.tableArgs.e2e]: [],
    [HISTORIES_TABLE_PROPS.tableArgs.e2e]: [
        'versions__event'
    ],
    [IDENTITIES_TABLE_PROPS.tableArgs.e2e]: [
        'hostname'
    ],
    [ORGANIZATIONS_TABLE_PROPS.tableArgs.e2e]: [
        'type'
    ],
    [TASKS_TABLE_PROPS.tableArgs.e2e]: [
        'application'
    ],
    [TENANTS_TABLE_PROPS.tableArgs.e2e]: [
        'environment'
    ],
    [USERS_TABLE_PROPS.tableArgs.e2e]: [
        'profile'
    ],
    [INTEGRATION_APPS_TABLE_PROPS.tableArgs.e2e]: [],
    [INTEGRATION_APPS_SETTINGS_TABLE_PROPS.tableArgs.e2e]: [],
};